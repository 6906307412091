import { Component } from '@angular/core';
import { MasipService } from '../../services/masip.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.scss'
})
export class SettingsComponent {

  queue: any[] = [];
  currentView: string = 'fiber';
  actualPage: number = 1;
  totalPages: number = 1;
  searched: string = '';
  initialDate: string = '';
  endDate: string = '';
  fiscalID: string = '';

  loading: boolean = false;
  constructor(private masipService: MasipService, private authService: AuthService) { };

  ngOnInit() {
    this.masipService.setApiLoadingGlobal(false);
    var auxFiscalID = localStorage.getItem('accessAsUser');
    if (auxFiscalID) {
      this.fiscalID = auxFiscalID;
    }
    this.getQueue();
    document.getElementById('content-scroll')?.addEventListener('scroll', (event) => {
      this.onScroll(event);
    }, true);
  }

  dateFilterChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
    this.initialDate = this.formatDate(dateRangeStart.value);
    this.endDate = this.formatDate(dateRangeEnd.value);
    this.getQueue();
  }

  resetDateFilter(picker: any) {
    picker.select(undefined);
    this.initialDate = '';
    this.endDate = '';
    this.getQueue();
  }

  changeView(view: string) {
    this.currentView = view;
    this.actualPage = 1;
    this.totalPages = 1;
    this.getQueue();
  }

  searchData(by: any) {
    this.searched = by;
    this.getQueue();

  }

  formatDate(date: string): string {
    var aux = date.split('/');
    var day = aux[0];
    var month = aux[1];
    if (day.length == 1) {
      day = "0" + day;
    }
    if (month.length == 1) {
      month = "0" + month;
    }
    return aux[2] + '-' + month + '-' + day;
  }

  getQueue() {
    this.loading = true;
    this.masipService.getQueue(this.fiscalID, this.actualPage, this.initialDate, this.endDate, this.searched, this.currentView).subscribe(resp => {
      this.queue = resp.data;
      this.totalPages = resp.last_page;
      this.actualPage = resp.current_page;
      this.loading = false;
      setTimeout(() => {

        // this.authService.refresh().subscribe(resp => { });
      }, 1000);

      this.loading = false;
    })
  }

  onScroll(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && this.actualPage <= this.totalPages) {
      this.actualPage++;
      this.masipService.getQueue(this.fiscalID, this.actualPage, this.initialDate, this.endDate, this.searched, this.currentView).subscribe(resp => {
        var aux = this.queue;
        this.queue = aux.concat(resp.data);
        this.totalPages = resp.last_page;
        this.actualPage = resp.current_page;
        this.loading = false;
      }, error => {

        this.loading = false;
      })
    }
  }
}
